import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import "./Loader.css";
import IpeLogo from "src/images/IPELOGO.svg";
import IpeLogo2 from "src/images/buttrflyload.png";

gsap.registerPlugin(MotionPathPlugin);

const DashboardLoadingScreen = () => {
  const butterflyRef = useRef(null);
  const ipeLogoRef = useRef(null);
  const ipeLogo2Ref = useRef(null); // Reference for the second logo
  const bubbleRef = useRef(null);
  const [animationCompleted, setAnimationCompleted] = useState(false); // State to track animation completion

  useEffect(() => {
    const vw = window.innerWidth;
    const vh = window.innerHeight;

    const logoRect = ipeLogoRef.current.getBoundingClientRect();
    const targetX = logoRect.left + logoRect.width / 2;
    const targetY = logoRect.top + logoRect.height / 2 - 50;

    const path = [
      { x: vw * 0.9, y: vh * 0.1 },
      { x: vw * 0.7, y: vh * 0.3 },
      { x: vw * 0.9, y: vh * 0.1 },
      { x: vw * 0.2, y: vh * 0 },
      { x: targetX, y: targetY },
    ];

    const tl = gsap.timeline();

    // Butterfly motion path animation
    tl.set(butterflyRef.current, { x: vw, y: -100 }).to(butterflyRef.current, {
      duration: 4,
      motionPath: {
        path: path,
        autoRotate: false,
      },
      ease: "power1.inOut",
      onComplete: () => {
        // Set butterfly's position to the exact center of the ipeLogo
        gsap.set(butterflyRef.current, {
          x: targetX - (butterflyRef.current.clientWidth / 2 - 55), // Center horizontally
          y: targetY - (butterflyRef.current.clientHeight / 2 - 33), // Center vertically
        });

        // Fade out the butterfly and fade in the second logo (IpeLogo2)
        gsap.to(butterflyRef.current, {
          // duration: 1,
          opacity: 0,
          // ease: 'power1.inOut',
          onComplete: () => {
            // Hide the butterfly and show the second logo
            gsap.set(butterflyRef.current, { display: "none" });
            gsap.set(ipeLogo2Ref.current, { display: "block" });
            gsap.to(ipeLogo2Ref.current, { opacity: 1, duration: 1 });

            // Continue with the bubble animation
            continueAnimation();
          },
        });
      },
    });

    const continueAnimation = () => {
      gsap.set(bubbleRef.current, { display: "block", scale: 0.1 }); // Show bubble with small initial scale

      // Start the blue-bubble zoom animation with a 2-second delay
      gsap.to(bubbleRef.current, {
        delay: 1, // 2-second delay before starting the bubble zoom
        duration: 2, // Total duration for the blue-bubble zooming
        scale: 450, // Zoom the bubble to cover the screen
        ease: "power2.inOut",
        onStart: () => {
          // After the blue-bubble starts zooming, fade out the butterfly, ipeLogo, and ipeLogo2
          gsap.to(
            [butterflyRef.current, ipeLogoRef.current, ipeLogo2Ref.current],
            {
              delay: 1, // Start fading 1 second after the blue-bubble starts zooming
              duration: 0.5, // Short fade-out duration for these elements
              opacity: 0,
              ease: "power2.inOut",
              onComplete: () => {
                // Hide these elements after they are invisible
                gsap.set(
                  [
                    butterflyRef.current,
                    ipeLogoRef.current,
                    ipeLogo2Ref.current,
                  ],
                  { display: "none" }
                );
              },
            }
          );
        },
        onComplete: () => {
          // Once the bubble zooms to full size, start the fading and blurring animation
          gsap.to(bubbleRef.current, {
            duration: 1, // Duration for bubble fading and blurring
            opacity: 0,
            filter: "blur(20px)", // Apply blur effect
            ease: "power2.inOut",
            onComplete: () => {
              // Hide the bubble after it's invisible
              gsap.set(bubbleRef.current, { display: "none" });
              // Set the animation as completed
              setAnimationCompleted(true);
            },
          });
        },
      });
    };
  }, []); // The empty dependency array ensures this effect runs only once

  return (
    <div
      className={`loadercontainer ${animationCompleted ? "fade-out2" : ""}`}
      id="loaderContainer"
    >
      <img
        src={IpeLogo}
        alt="IPE Logo"
        className="ipe-logo"
        ref={ipeLogoRef}
        id="ipeLogo"
      />

      <svg
        className="butterfly"
        ref={butterflyRef}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 65 65"
      >
        <g>
          <path
            className="left-wing"
            fill="#6069b0"
            d="M20.79,46.37c5.41-1.68,6.72-2.3,6.88-13.47S18.06,2.75,13,1.06C10.33,0.16,6.11,0.42,3.84,9c-2.64,10,8.69,23.77,8.69,23.77s-7.65-4.91-9.72-3.53S-1.44,34,2.9,40.55c4.58,6.89,12.47,7.51,17.89,5.82"
          />
          <path
            className="right-wing"
            fill="#3c50a2"
            d="M38.52,31.35c-7.45,4.8-10.89,9.71-11.08,13.23s3.67,4.61,6.8,3.8s6.27-5.56,6-6.32s-4.09-0.16-4.09-0.16s6.42-2.88,10.2-6.52S50,28.17,49,27s-3-0.46-10.44,4.33"
          />
        </g>
      </svg>

      <img
        src={IpeLogo2}
        alt="IPE Logo 2"
        className="ipelogo2"
        ref={ipeLogo2Ref} // Second logo reference
        style={{ display: "none", opacity: 0 }} // Initially hidden
      />

      <div className="blue-bubble" ref={bubbleRef}></div>
    </div>
  );
};

export default DashboardLoadingScreen;
